@import url("https://p.typekit.net/p.css?s=1&k=tmo3ugh&ht=tk&f=26053.26054.26056.26060.26061.26062&a=795339&app=typekit&e=css");

@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-style:normal;
  font-weight: 500;
}

/* variables */
$color:               #000;
$background:          #fff;


$border:              .2em;
$gradient-a:          linear-gradient(90deg, #AEF3FC, #DEFFDE);
$gradient-b:          linear-gradient(90deg, #C9E6FF, #FFDEFF);
$gradient-c:          linear-gradient(90deg, #FFF6CE, #FFCDBE);
$gradient-a-inverted: linear-gradient(-90deg, #AEF3FC, #DEFFDE);
$gradient-b-inverted: linear-gradient(-90deg, #C9E6FF, #FFDEFF);
$gradient-c-inverted: linear-gradient(-90deg, #FFF6CE, #FFCDBE);

/* variables */
$color:               #111;
$background:          #fff;
$ease-in-quad:        cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic:       cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart:       cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint:       cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-expo:        cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ:        cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-quad:       cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic:      cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart:      cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint:      cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-expo:       cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ:       cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-quad:    cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic:   cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart:   cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint:   cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-expo:    cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ:    cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-bounce:         cubic-bezier(0.175, 0.885, 0.32, 1.275);
/* reset */

// Box-sizing reset
html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
}
// HTML5 display-role reset for older browsers: https://cssreset.com/scripts/eric-meyer-reset-css/
article, header, nav, section {
  display: block;
}
// typography reset
// use inherit to get the attribute from the parents element
h1, h2, h3, h4 {
  font-size: inherit; 
  font-weight: inherit;
}
a {
  text-decoration: inherit;
  color: inherit;
}
/* browser defaults */
::selection {
  color: $color;
  background-color: rgba(0,0,0,0);
}
/* browser defaults */
#main-section, .past-gig {
  h2, h3, h4, h5, p, span, p a {
    &::selection {
      color: $color;
      background-color: rgba(0,0,0,.15);
    }
  }
}
#main-section.luke-le-loup, .past-gig.luke-le-loup {
  h2, h3, h4, h5, p, span, p a {
    &::selection {
      background-color: #d0f7ee;
      background-image: linear-gradient(90deg, #AEF3FC, #DEFFDE);
    }
  }
}
#main-section.bestboi, .past-gig.bestboi {
  h2, h3, h4, h5, p, span, p a {
    &::selection {
      background-color: #e3e3fd;
      background-image: linear-gradient(90deg, #C9E6FF, #FFDEFF);
    }
  }
}
#main-section.projects, .past-gig.projects {
  h2, h3, h4, h5, p, span, p a {
    &::selection {
      background-color: #fbe3ca;
      background-image: linear-gradient(90deg, #FFF6CE, #FFCDBE);
    }
  }
}

// $gradient-a:          linear-gradient(90deg, #AEF3FC, #DEFFDE);
// $gradient-b:          linear-gradient(90deg, #C9E6FF, #FFDEFF);
// $gradient-c:          linear-gradient(90deg, #FFF6CE, #FFCDBE);
a, a:hover, a:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}
a img {
  border: 0; // Internet Explorer
}
/* helper classes */
// use .clearfix and .floated like this:
// <div class="clearfix">
//   <div class="floated">float a</div>
//   <div class="floated">float b</div>
//   <div class="floated">float c</div>
// </div>
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}
.floated {
  float: left;
}
.unselectable {
  user-select: none;
}
.ignore {
  pointer-events: none;
}
.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* typography */
html {
  font-size: 15px;
}
ul {
  font-size: 0;
  line-height: 0;
  li {
    font-size: 15px;
    line-height: 1;
  }
}
body {
  font-size: 1em;
  font-family: 'acumin-pro', sans-serif;
  line-height: 1;
}
// body:after {
//   content: ' ';
//   display: block;
//   position: fixed;
//   top: 0;
//   left: 50%;
//   width: $border;
//   height: 100%;
//   transform: translateX(#{$border * -.5});
//   background-color: red;
// }
h1, h2, h3, h4 {
  font-weight: 700;
  font-size: 1em;
}
h5 {
  font-weight: 500;
  font-size: 1em;
}
/* color */
html, body {
  // background: $color;
  color: $color;
  // height: 100%;
}
#main {
  background-color: $background;
}
// html {
//     background: $color;
// }
// main {
//     background-color: $background;
// }
#loading-screen {
  #body:not(.loading) {
    z-index: -1;
  }
  transition: opacity .5s $ease-out-expo 0s, visibility 0s linear 1s;
  visibility: hidden;
  #body.menu-is-open & {
    opacity: .85;
  }
  #body.loading & {
    visibility: visible;
    opacity: 1;
  }
}
/* header */
#header {
  width: 100%;
  height: #{3.5em + $border};
  background-color: $background;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  border-bottom: $border solid $color;
  padding: .2em .5em;
  z-index: 5;
  transition: transform 1s $ease-out-expo 0s;
  transform: translateY(0);
}
#main-nav {
  .menu-is-open & {
    transition: transform 1s $ease-out-expo 0s;
    transform: translateX(0);
  }
}
@media all and (min-width: 60em) {
  .menu-is-closed {
    #main-nav {
      transform: translateX(-101%)!important;
    }
  }
}
@media all and (min-width: 60em) {
  .menu-is-closed {
    #gigs {
      transform: translateX(101%)!important;
    }
  }
  #gigs {
    .menu-is-open & {
      transition: transform 1s $ease-out-expo 0s;
      transform: translateX(0);
    }
  }
}
#body.menu-is-closed #main-section-content {
  opacity: 1;
  transition: all 1s $ease-out-expo 0s;
  background: #fff;
  transform: translateY(0);

}
//  #logo-2 {
//    position: fixed;
//    top: 0px;
//    left: 8px;
//    opacity: .5;
//  }
//  @keyframes logo-1 {
//    from {
//      transform: rotate(0deg);
//    } to {
//      transform: rotate(-180deg);
//    }
//  }
//  @keyframes logo-2 {
//    from {
//      transform: rotate(-180deg);
//    } to {
//      transform: rotate(-360deg);
//    }
//  }
//  @keyframes form-a-1 {
//    from {
//      transform: rotate(0deg);
//    } to {
//      transform: rotate(90deg);
//    }
//  }
//  @keyframes form-a-2 {
//    from {
//      transform: rotate(90deg);
//    } to {
//      transform: rotate(180deg);
//    }
//  }
//  @keyframes form-b-1 {
//    from {
//      transform: rotate(0deg);
//    } to {
//      transform: rotate(90deg);
//    }
//  }
//  @keyframes form-b-2 {
//    from {
//      transform: rotate(90deg);
//    } to {
//      transform: rotate(180deg);
//    }
//  }
//  @keyframes form-c-1 {
//    from {
//      fill: none;
//      r: 1.75;
//      stroke: $color;
//      stroke-width: .8em;
//    } to {
//      r: 6.5;
//      stroke: $color;
//      stroke-width: .2em;
//    }
//  }
//  @keyframes form-c-2 {
//    from {
//      r: 6.5;
//      stroke: $color;
//      stroke-width: .2em;
//    } to {
//      r: 1.75;
//      stroke: $color;
//      stroke-width: .8em;
//    }
//  }
//  @keyframes form-d-1 {
//    from {
//      r: 6.5;
//      stroke: $color;
//      stroke-width: .2em;
//    } to {
//        r: 1.75;
//        stroke: $color;
//        stroke-width: .8em;
//    }
//  }
//  @keyframes form-d-2 {
//    from {
//        r: 1.75;
//        stroke: $color;
//        stroke-width: .8em;
//    } to {
//      r: 6.5;
//      stroke: $color;
//      stroke-width: .2em;
//    }
//  }
.click-response {
  // transform: translateZ(0);
  // transform-style: preserve-3d;
  // backface-visibility: hidden;
  transition: transform .5s $ease-out-expo;
}

#logo.active .click-response-form-a {
  transform: translate(.125em, .125em); // rotate(20deg);
}
#logo.active .click-response-form-b {
  transform: translate(-.125em, -.125em); // rotate(20deg);
}
#logo.active .click-response-form-c {
  transform: translate(-.125em, .125em); // rotate(20deg);
}
#logo.active .click-response-form-d {
  transform: translate(.125em, -.125em); // rotate(20deg);
}
//  #logo-group {
//    animation: logo-1 3s $ease-out-circ 1 normal forwards;
//    .form-a {
//      animation: form-a-1 3s $ease-out-circ 1 normal forwards;
//    }
//    .form-b {
//      animation: form-b-1 3s $ease-out-circ 1 normal forwards;
//    }
//    .form-c {
//      animation: form-c-1 .5s $ease-out-circ 1 normal forwards;
//    }
//    .form-d {
//      animation: form-d-1 .5s $ease-out-circ 1 normal forwards;
//    }
//    .hover & {
//      animation: logo-2 3s $ease-out-circ 1 normal forwards;
//      .form-a {
//        animation: form-a-2 3s $ease-out-circ 1 normal forwards;
//      }
//      .form-b {
//        animation: form-b-2 3s $ease-out-circ 1 normal forwards;
//      }
//      .form-c {
//        animation: form-c-2 .5s $ease-out-circ 1 normal forwards;
//      }
//      .form-d {
//        animation: form-d-2 .5s $ease-out-circ 1 normal forwards;
//      }
//    }
//    .form-c {
//      fill: none;
//      r: 1.75;
//      stroke: $color;
//      stroke-width: .8em;
//    }
//  }
#headline {
  padding-bottom: .1em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
#email {
  padding-bottom: .3em;
}
#email {
  font-size: 1.75em;
}
/* main */
#main {
    padding-top: 3.5em;
  width: 100%;
  overflow-x: hidden;
  h2 {
    // font-size: 1.5em;
    //  @media all and (min-width: 231px) {
    //    font-size: 9.740vw;
    //    font-size: 13.636vw;
    //  }    
    //  @media all and (min-width: 385px) {
    //    font-size: 2.5em;
    //  }
    font-size: 2.5em;
    @media all and (max-width: 385px) {
      font-size: 2.25em;
      font-size: 9.740vw;
    }
    @media all and (max-width: 308px) {
      font-size: 2em;
    }
    font-weight: 700;
  }
}
#nav {
  top: 0;
  position: absolute;
  z-index: 3; // firefox
}
#gigs-header {
  display: table;
  width: 100%;
  min-height: 5em;
  // height: calc(#{100vh * .25} - #{3.5em * .25 + 17em * .25 - .2em * .25});
  // height: calc(var(--vh, 1vh) * 25 - #{3.5em * .25 + 17em * .25 - .2em * .25});


    height: calc(25vh - 5em);
    height: calc(var(--vh, 1vh) * 25 - 5em);
    max-height: #{300px * .5};
    @media all and (max-width: 60em), all and (orientation: portrait) {
      max-height: 7em;
    }

  border-bottom: $border solid $color;
  h2 {
    position: relative;
    display: inline-block;
    display: table-cell;
    vertical-align: middle;
  }
}
#gigs-nav {
  //transform: translateZ(0);
  height: 2.5em;
  padding: 0 3.5em 0;
  @media all and (max-width: 385px) {
    padding: 0 13.636vw 0;
  }
  position: -webkit-sticky;
  position: sticky;
  -webkit-transform: translateZ(0);
  & > * {
    -webkit-transform: translateZ(0);
  }
  // top: #{3.5em + $border};
  top: 0;
  z-index: 4;
  background-color: $background;
  border-bottom: $border solid $color;
  ul {
    padding: 0 3.5em 0;
    @media all and (max-width: 385px) {
      padding: 0 13.636vw 0;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    padding: 3;
    li {
      font-weight: 700;
      list-style-type: none;
      display: inline-block;
      padding-bottom: .3em;
      &:not(:last-of-type) {
        padding-right: 6em;
      }
    }
  }
}
#main-section-header, #gigs-nav {
  nav, ul, a {
    height: 100%;
  }
  li {
    display: inline-block;
    opacity: .3;
    &.active {
      opacity: 1;
    }
  }
}
/* footer */
/* pattern */
.pattern {
  height: 1em; // 15px
  width: 100%;

  #nav & {
    bottom: #{-1em - $border - .5em};
    min-width: 10em;
    position: absolute;
    right: -.3em;
    width: 30%;
    z-index: 3;
  }

  #main-nav & {
    bottom: #{1.5em};
    min-width: 10em;
    position: absolute;
    right: #{2.6em};
    transition: right 1s $ease-out-quad;
    width: 30%;
    z-index: 3;
    @media all and (max-width:30em) {
      right: #{1em - $border};
    }
  }
}

/* home/gigs */
.gig {

  .gig-cover,
  &::before {
    transition: transform .5s $ease-out-circ;
    will-change: transform;
  }
}

.external-link {

  &:hover {

    .gig-cover {
      transform: translateY(.5em);
    }

    .gig:before {
      transform: translate(-1em, .5em)!important;
    }
  }
}

#gigs {
  h2 {
    text-align: center;
  }
  .gig {
    // padding: 1em;
    position: relative;
  }
  .gig {
    padding: 1em;
    &::before {
      content: ' ';
      height: 100%;
      height: calc(100%  + #{$border});
      left: -0.2em;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      transform: translate(-1em, 1em);
      transition: transform 0.5s $ease-out-circ;
      width: 100%;
      width: calc(100% + #{$border * 2} + 2em);
      z-index: -1;
    }
  }
  .gig-cover {
    position: relative;
    top: -2.2em;
    margin-bottom: -2.2em;
  }
 .gig.luke-le-loup {
    &::before {
      background-image: $gradient-a;
    }
  }
  .gig.bestboi {
    &::before {
      background-image: $gradient-b;
    }
  }
  .gig.projects {
    &::before {
      background-image: $gradient-c;
    }
  }
  .gig-cover {
    width: 100%;
  }
  .gig-date {
    float: left;
    padding-right: .8em;
    span {
      display: block;
    }
  }
  .gig-wrapper {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 1em;
    &, * {
      pointer-events: auto;
      user-select: auto;
      &:selection {
        background-color: red;
        color: green;
      }
    }
  }
  .gig-info {

  }
  .gig-date-month {
    text-transform: uppercase;
  }
//  .gig-date-year {
//    letter-spacing: .05em;
//  }
  .gig-date-month, .gig-date-year {
    text-align: center;
  }
  .gig-date-day {
    // font-size: 1.5em;
    //  @media all and (min-width: 231px) {
    //    font-size: 9.740vw;
    //    font-size: 13.636vw;
    //  }    
    //  @media all and (min-width: 385px) {
    //    font-size: 2.5em;
    //  }
    font-size: 2.5em;
    @media all and (max-width: 385px) {
      font-size: 2.25em;
      font-size: 9.740vw;
    }
    @media all and (max-width: 308px) {
      font-size: 2em;
    }
    font-weight: 700;
    // @media all and (min-width:1200px) {
    //   font-size: #{1.250vw * 2.5};
    // }
  }
  .gig-title {
    // font-size: 1.5em;
    //  @media all and (min-width: 231px) {
    //    font-size: 9.740vw;
    //    font-size: 13.636vw;
    //  }    
    //  @media all and (min-width: 385px) {
    //    font-size: 2.5em;
    //  }
    font-size: #{2.5em * .75};
    padding-top: .25em;
    @media all and (max-width: 385px) {
      font-size: #{2.25em * .75};
      font-size: #{9.740vw * .75};
    }
    @media all and (max-width: 308px) {
      font-size: #{2em * .75};
    }
    transition: all .5s $ease-out-quad;
    min-height: 1em;
    // @media all and (max-width: 385px) {
    //   // min-height: 9.740vw;
    //   padding-top: .35em;
    //   // padding-top: calc(9.740vw - 1.2em);
    //   // line-height: 1.2;
    //   font-size: 1em;
    // }
    font-weight: 700;
    // @media all and (min-width:1200px) {
    //   font-size: #{1.250vw * 2.5};
    // }
  }
  .gig-location {
    padding-top: 1.13em;
    transition: all .5s $ease-out-quad;
    // @media all and (max-width: 385px) {
    //   padding-top: 0;
    // }
  }
}
//  body:after {
//    content: ' ';
//    width: 100%;
//    height: 3.5em;
//    position: fixed;
//    top: 13.6em;
//    left: 0;
//    display: block;
//    // background-color: rgba(255,0,0,.5);
//    z-index: 999;
//    pointer-events: none;
//  }
#main-nav {
  padding-top: 2.3em;

  li {
    display: table;
    height: calc(25vh - 1.5em);
    height: calc(var(--vh, 1vh) * 25 - 1.5em);
    max-height: #{300px * .5};
    max-height: calc(#{300px * .5} + 3.2em);
    position: relative;
    width: 100%;
    padding: 0 3.5em 3em 3.5em;
    transition: padding 1s $ease-out-quad;

    @media all and (max-width:30em) {
      padding: 0 1em 3em 1em;
    }

    @media all and (max-width: 60em), all and (orientation: portrait) {
      max-height: 10em;
    }
  }

  a {
    position: relative;
    width: 100%;
    height: 100%;
    border: $border solid $color;
    padding-left: 1.8em;
    padding-bottom: 0.2em;
    display: table;
    span {
      display: table-cell;
      vertical-align: middle;
      font-size: 2.5em;
      @media all and (max-width: 385px) {
        font-size: 2.25em;
        font-size: 9.740vw;
      }
      @media all and (max-width: 308px) {
        font-size: 2em;
      }
      font-weight: 700;
    }
  }
  li{
    a::before {
      content: ' ';
      width: 100%;
      width: calc(100% + #{$border});
      height: 100%;
      height: calc(100%  + #{$border});
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(1em, 1em);
      will-change: transform;
      z-index: -1;
      transition: transform .5s $ease-out-circ;
    }
    a.hover::before, a:hover::before {
      transform: translate(.5em, .5em);
    }
  }
  li.luke-le-loup {
    a::before {
      background-image: $gradient-a;
    }
  }
  li.bestboi {
    a::before {
      background-image: $gradient-b;
    }
  }
  li.projects {
    a::before {
      background-image: $gradient-c;
    }
  }
}
.gig-date-and-title {
  display: flex;
}
html {
//  overflow-x: hidden;
//  height: 100%; // bug
}
@media all and (max-width:60em) {
  #nav {
    background-color: $background; // firefox offset, hidden/z-index
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain; // experimental
    -webkit-overflow-scrolling: touch;
    position: fixed; // firefox offset? absolute?
    top: 3.5em;
    left: 0;
    width: 100%; // firefox offset?
    height: 100%;
    height: calc(100% - 3.5em);
  }
  #gigs {
  border-top: 0.2em solid #111;
  }
}
#main-nav, #gigs {
  width: 100%;
}
@media all and (min-width:60em) {
  #main-nav, #gigs {
    background-color: $background;
  }
  #main-nav {
    border-right: $border solid $color;
    position: fixed;
    // top: 3.7em;
    top: 0;
    left: 0;
    width: 50%;
    width: calc(50% + .1em);
    height: 100%;
    // height: calc(100% - 3.7em);
    overflow: auto;
    // -webkit-overflow-scrolling: touch;
  }
  #gigs {
    border-left: $border  solid $color;
    position: fixed;
    left: 50%;
    left: calc(50% - .1em);
    // top: 3.7em;
    top: 0;
    width: 50%;
    width: calc(50% + .1em);
    height: 100%;
    // height: calc(100% - 3.7em);
    // overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain; // experimental
  }
}
.gig {
  border: $border solid $color;
  margin: 3.5em;
  transition: margin 1s $ease-out-quad;
    @media all and (max-width: 385px) {
      margin: 13.636vw;
    }
    @media all and (max-width:30em) {
      margin: 3.5em 1em;
    }
}

.gig-title {
  mix-blend-mode: multiply;
}
.gig-cover-container {
  position: relative;
//  &::after {
//    content: 'download ICS in ' attr(data-countdown);
//    position: absolute;
//    transform: rotate(-90deg);
//    transform-origin: top left;
//    top: 99%;
//    left: 0em;
//    z-index: -2;
//    font-weight: 500!important;
//    font-size: 1em;
//    // -webkit-font-smoothing: antialiased;
//    @media all and (max-width: 453px) {
//      content: 'ICS in ' attr(data-countdown);
//    }
//  }
}
.gig-cover {
  z-index: 2;
}
.menu-item-link {
  min-height: 5.2em;
}
.menu-item.active {
  a::before {
    transform: translate(0, 0)!important;
  }
}
.gig.active .gig-cover {
  transform: translateX(2.2em);
}
.gig.active::before {
  transform: translate(0em, 0em)!important;
}
.gig.active.pattern {
  width: 100%;
}
canvas {
  position: relative;
}
body > .p5Canvas {
  display: none;
}
//  #nav {
//    transition: transform 1s $ease-out-expo;
//  }
#main-nav, #gigs {
  transition: transform 1s $ease-out-expo; // , width 1s $ease-out-expo;
  // transform: translateX(0);
}
//  .menu-is-closed, .menu-is-open { 
//    #main-nav, #gigs {
//      // transition: transform 1s $ease-out-expo, width 1s $ease-out-expo;
//      // transform: translateZ(0);
//    }
//      @media all and (min-width: 60em) {
//        transition: transform 1s $ease-out-expo;
//      }
//  }
p + a, .footer a {
  text-decoration: underline;
}
.footer {
  width: 100%;
  display: table;
  text-align: center;
  border-top: $border solid $color;
  height: calc(#{100vh * .25} - #{3.5em * .25 - .2em * .25 - 3.4em});
  height: calc(var(--vh, 1vh) * 25 - #{(3.5em + $border) * .25 + 2.5em * .25 - 3.4em});
  max-height: #{300px * .5};
  max-height: calc(#{300px * .5});
  padding: 3em 0 3.5em 0;
  position: relative;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border;
    transform: translateY(.2em);
    background-color: $color;
  }
}
.footer-wrapper {
    display: table-cell; // row; //  block; // table-cell;
    vertical-align: middle;
    letter-spacing: .03em;
    font-size: .8em!important;
    line-height: 1.6;
}
#headline {
  height: 3.4em;
}
.menu-is-open {
  // overflow: hidden; // safari problem // new
  .article {
    transition: opacity 1s $ease-out-expo;
    opacity: .85;
  }
  #title {
    transform: translateY(1.125em) scale(1);
    letter-spacing: 0em;
  }
  #subtitle {
    position: relative;
    z-index: -1;
    opacity: 0;
  }
}
#title, #subtitle {
  transition: transform .5s $ease-out-expo, opacity .25s $ease-out-expo;
}
#nav {
  @media all and (max-width: 60em) {
    transition: border-color 0s linear 1s, transform 1s $ease-out-expo;
    border-top: $border solid $color;
  }
}
//  #main-nav, #gigs {
//    transition: transform 1s $ease-out-expo;
//    @media all and (min-width: 60em) {
//      transform: translateX(0%);
//      // transition: transform 1s $ease-out-expo;
//    }
//  }
.menu-is-closed {
  #nav {
    @media all and (max-width:60em) {
      transform: translateY(0);
    }
    // overflow: hidden; // safari problem
  }
  #title {
    transition: transform .5s $ease-out-expo, letter-spacing .5s $ease-out-expo;
  }
  #subtitle {
    transform: translateY(.6em);
    // transition: transform .5s $ease-out-expo;
  }
  #title {
    transform: translateY(.6em) scale(.75);
    letter-spacing: .05em;
  }
  #subtitle {
    opacity: 1;
    // transform: translateY(0);
  }
  overflow-x: hidden;
  #nav, #main-nav, #gigs {
    pointer-events: none;
  }
  #nav {
    // transform: translateY(0);
    @media all and (max-width: 60em) {
      // transition: transform .5s $ease-out-circ 1s;
      transition: transform 1s $ease-out-quint .25s;
      transform: translateY(101%);
    }
  }
  #main-nav {
    // transform: translateY(-100%);
    // transform: translateX(0);
    @media all and (min-width: 60em) {
      transform: translateX(-101%);
    }
  }
  #gigs {
    // transform: translateX(0);
    // transform: translateY(200%);
    @media all and (min-width: 60em) {
      transform: translateX(101%);
    }
  }
}
//  #main-nav {
//    transform: translateX(0);
//  }
//  #gigs {
//    transform: translateX(0);
//  }
h1, h2, h3, h4, h5, p, li {
  cursor: default;
}
// * {
//   user-select: none;
//   // -webkit-touch-callout: none;
// }
@keyframes gradient-animation { 
    0% { 
      background-position: 0% 50%
    }
    50% { 
      background-position: 100% 50%
    }
    100% { 
      background-position: 0% 50%
    }
}
#main-section {
  position: relative;
  z-index: 1;
}
// #nav {}
#main-section-content {
  // padding-top: 2.5em;
  position: fixed;
  top: 3.7em; // #{3.7em + 2.5em};
  left: 0;
  height: calc(100% - 3.7em);
  // margin-top: -.2em;
  padding-top: 2.5em;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain; // experimental
  width: 100%;
  z-index: 1;
}
#main-section-header {
  z-index: 2;
  position: fixed;
  top: 3.7em;
  left: 0;
  right: 0;
  width: 100%;
  height: 2.5em;
  max-width: 1800px;
  margin: 0 auto;
  background-color: $background;
  background-size: 200% 100%;
  background-size: 200vw 100%;

  text-align: center;
  border-bottom: $border solid $color;
  font-weight: 700;
  animation: gradient-animation 5s linear infinite;
  #main-section.luke-le-loup & {
    background-image: $gradient-a-inverted;
  }
  #main-section.bestboi & {
    background-image: $gradient-b-inverted;
  }
  #main-section.projects & {
    background-image: $gradient-c-inverted;
  }
  .menu-is-open & {
    transition: transform .5s $ease-out-circ 1s;
    transform: translateY(-2.5em);
    animation-play-state: paused;
  }
  .menu-is-closed & {
    transition: transform 1s $ease-out-quint .25s;
    transform: translateY(0);
    animation-play-state: running;
  }
  .main-section-header-3-items .main-section-header-item {
    text-align: center;
    &:first-of-type {
      text-align: right;
      padding-right: 1.5em;
    }
    &:last-of-type {
      text-align: left;
      padding-left: 1.5em;
    }
  }
  li {
    width: 25%;
    max-width: 12em;
  }
  a {
    display: inline-block;
    padding: .6em .5em .7em;
    // width: 12em;
  }
}
//  .container {
//    @media all and (min-width: 60em) {
//      width: 50%;
//    }
//  }
.text-inner-container {
  width: 100%;
  padding: 1em 1em 6em;
  @media all and (min-width: 60em) {
    padding: 1em;
  }
  // @media all and (min-width: 60em) {
    max-width: 28em;
  // }
  margin: auto;
}
//  .release {
//    display: flex;
//    flex-flow: column wrap;
//  }
@media all and (min-width: 60em) {
//    .cover-container {
//      order: 1;
//      flex: 1 0 50%;
//    }
  .listenon-container {
//      order: 2;
    // padding-left: 1em;
    padding-top: 2.2em;
//      flex: 1 0 50%;
  }
//    .info-container {
//      order: 3;
//      flex: 1 0 50%;
//    }
}
.listenon-container {
  @media all and (max-width: 60em) {
    padding-left: 3.3em;
  }
  @media all and (min-width: 60em) {
    padding-left: 4.3em;
  }
}
.listenon {
    position: relative;
    li {
      position: relative;
      img {
        width: 2.4em;
        height: 2.4em;
        position: absolute;
        top: 0;
        left: -3.5em;
      }
      &:first-of-type {
        margin-top: 0;
      }
      @media all and (max-width: 60em) {
        &:last-of-type {
          margin-bottom: 2em;
        }
      }
      //  &:last-of-type {
      //    margin-bottom: 5.2em;
      //  }
      // @media all and (max-width: 60em) {
        width: 100%;
        max-width: 24.7em;
        // margin: 0 auto;
      // }
    }
}
.info-inner-container {
  padding-bottom: 2.2em;
}
.releases-inner-container {
  padding-bottom: 3em;
}
.text-container, .listenon {
  li {
    list-style-type: none;
    font-weight: 700;
    width: 100%;
    height: 2.7em;
    text-align: center;
    border: $border solid $color;
    margin-top: 1em;
    a {
      padding: .5em 0 .5em;
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    &:hover {
      background-color: $color;
      color: $background;
    }
  }
}
.text-container {
  li {
    &:first-child {
      margin-top: 3.5em;
    }
  }
  @media all and (max-width: 60em) {
    padding-bottom: 1em;
    width: 100%;
  }
  @media all and (min-width: 60em) {
    width: calc(50% - .1em);
  }
  p {
    letter-spacing: .02em;
    line-height: 1.4;
  }
  .pattern {
    box-sizing: border-box;
    padding: 1em 0 2.6em;
  }
  @media all and (min-width: 60em) {
    padding: 3em 1em;
    .pattern {
      width: 100%;
    }
  }
}
.image-container {
  // width: calc(50% + .1em);
  // overflow: hidden;
  width: 100%;
  transform: translateY(-1.2em);
  @media all and (min-width: 60em) {
    padding-bottom: 5em;
  }
  img {
    // height: calc(100% + #{$border * 2}); // why is it not projecting
    width: auto;
  }
  @media all and (max-width: 60em) {
    float: none;
    // max-width: 26em;
    // width: 100%;
    // height: 50%;
  }
  .image-inner-container {
    // width: calc((100vw - 25em) * .5 + #{25em - 0em - .4em});
    // height: calc((100vw - 2.25em) * .8 - .2em);
    height: calc(((100vw - 26em) * .5 + 26.1em) * .8 - .2em);
    @media all and (max-width: 60em) {
      position: relative;
      width: calc(100% + .4em);
      max-height: calc(#{100vw / 5 * 4} + .2em);
      max-width: calc((100vw - 26em) * .5 + 26.1em);
    }
    @media all and (min-width: 60em) {
      // width: calc(50vw + .7em);
      // height: calc((50vw - .2em) * .8);
      // width: calc(100% + 1em);
      height: calc((50vw) * .8);
    }
    @media all and (min-width: 1800px) {
      height: #{900px * .8};
    }
  }
  img {
    width: 100%;
    height: auto;
    // height: calc(100% + .6em);
    @media all and (max-width: 60em) {
      // width: auto;
    }
  }
  .article.even & {
    //  .image-inner-container {
    //    margin-left: 0em;
    //  }
    img {
      transform: translate(1em, 1em);
    }
    @media all and (max-width: 60em) {
      margin-left: -0.2em;
      .image-inner-container {
        margin-left: auto;
        margin-right: -.4em;
      }
      //  img {
      //    opacity: .5;
      //    //position: absolute;
      //    // left: 0;
      //    //transform: translate(0, 1em);
      //  }
    }
    @media all and (min-width: 60em) {
      // padding-right: .8em;
      width: calc(50% - .7em);
    }
  }
  .article.odd & {
    .image-inner-container {
      margin-left: -.2em;
    }
    img {
      transform: translate(-1em, 1em);
    }
    @media all and (max-width: 60em) {
      img {
        // position: absolute;
        // right: 1em;
        // transform: translate(0em, 1em);
      }
    }
    @media all and (min-width: 60em) {
      // padding-right: .8em;
//      margin-left: -1em;
      width: calc(50% - .7em);
    }
  }
}
.image-container img {
  // width: auto;
  // height: auto;
  mix-blend-mode: multiply;
}
.image-inner-container {
    width: calc(100% + 1em);
    position: relative;
    border: $border solid $color;
}
.js .gigs:not(.active) {
  display: none;
}
.no-js .gigs {
  display: none;
}
.no-js .gigs:target {
  display: block;
} 
.menu-item-link, #logo, .gig {
  * {
    pointer-events: none;
  }
}
.past-gigs-header {
  padding: 3.2em 0 .8em;
  border-bottom: $border solid $color;
}
#past-gigs {
  padding-bottom: 5.5em;
  h2 {
    text-align: left;
    font-size: 1.5em;
  }
  h3 {
    font-weight: 500;
  }
  article {
    line-height: 1.4;
    padding: .5em 0 1em;
    border-bottom: #{$border * .5} solid $color;
  }
  section {
    padding: 0 3.5em;
    transition: padding 1s $ease-out-quad;
    @media all and (max-width: 30em) {
      padding: 0 1em;
    }
  }
}
//  .menu-is-closed {
//    transform: translateX(0px);
//    overflow-x: hidden;
//  }
body {
  @media all and (min-width: 1800px) {
    transform: translateX(0px);
  }
  @media all and (min-width: 1800px) {
    &:before {
      content: ' ';
      display: block;
      height: 100%;
      width: .2em;
      background: $color;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
    }
    &:after {
      content: ' ';
      display: block;
      height: 100%;
      width: .2em;
      background: $color;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99999;
    }
  }
}
body {
  max-width: 1806px;
  max-width: calc(1800px + .4em);
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
}
#nav {
  // position: relative;
  @media all and (min-width: 60em) {
    overflow: hidden;
    width: 100%;
    top: 3.7em;
    height: calc(100% - 3.7em);
  }
}
#main-nav, #gigs {
  @media all and (min-width: 60em) {
    position: absolute;
    top: 0;
  }
}
html {
  position: relative;
  background-color: $background;
  background-size: 200% 200%;
  animation: gradient-animation 5s linear infinite;
  width: 100%;
  height: 100%;
  &.current-page--luke-le-loup {
    background-image: $gradient-a-inverted;
  }
  &.current-page--bestboi {
    background-image: $gradient-b-inverted;
  }
  &.current-page--projects {
    background-image: $gradient-c-inverted;
  }
}
#main-nav {
  // position: relative;
  // z-index: 999;
  // max-width: 1800px;
  // margin: 0 auto;
  // overflow: hidden;
  overflow: auto;
}
//  html, body, #main, #main-nav {
//    max-width: 1800px;
//    margin: 0 auto;
//  }
#main-section-content {
  max-width: 1800px;
  margin: 0 auto;
  right: 0;
}
@media all and (min-width: 60em) {
  #main-nav, #gigs {
    max-width: 902px;
  }
}
#main-nav {
    right: 50%;
    @media all and (min-width: 60em) {
      right: calc(50% - .1em);
    }
    margin-left: auto;
}
#gigs {
    left: 50%;
    @media all and (min-width: 60em) {
      left: calc(50% - .1em);
    }
    margin-right: auto;
}
//  @media all and (min-width:1365px) {
//    html, ul li {
//      font-size: #{1.1vw}; // ???
//    }
//  }
// .projects-header {
//   padding: 0 3.5em;
//   // text-align: center;
// }
.projects {
  padding: 3em 3em 0em;
  transition: padding 1s $ease-out-quad;
  .projects-container {
    padding: 0 0 0;
  }
  @media all and (max-width: 390px) {
    // padding: 3em 11.667vw 0em;
    .projects-container {
      padding: 3em 0 0;
    }
  }
  @media all and (max-width: 30em) {
    padding: 3em 1em 0;
  }
    @media all and (min-width: 60em) {
    padding: 3em 0 0;
    .projects-container {
      padding: 0 3.5em 0;
    }
  }
  &:last-of-type {
    padding-bottom: 7em;
  }
  .pattern {
    padding: 1em 0;
  }
}
.project {
  width: 50%;
  width: calc(50% - #{3.5em / 2 * 3});
  padding: 1em 1em 0;
  position: relative;
  border: $border solid $color;
  margin: 3.5em 0 0 3.5em;
  &:not(.active) {
    *:not(.extend) {
      pointer-events: none;
    }
  }
  li {
    list-style-type: none;
    font-weight: 700;
    width: 100%;
    // max-width: 28em;
    height: 2.7em;
    text-align: center;
    border: $border solid $color;
    margin: 1em auto 0;
    &:first-child {
      margin: 3.5em auto 0;
    }
    a {
      padding: .5em;
      width: 100%;
      height: 100%;
      display: inline-block;
      &:hover {
        background-color: $color;
        color: $background;
      }
    }
  }
}
.project-cover-container {
  position: relative;
  padding-bottom: 1em;
}
.project-cover {
  position: relative;
  // top: -2.2em;
  margin-bottom: -2.2em;
  transform: translateY(-2.2em);
  width: 100%;
  z-index: 0;
  // transition: transform .5s $ease-out-circ;
  transition: transform .8s $ease-out-quint;
  will-change: transform;
  .project.active &, .project:not(.active):hover & {
  transition: transform .6s $ease-out-quint;
    transform: translateY(-1.7em);
  }
}
.project-title, #error-inner-container {
//  font-size: 2.5em;
//  font-weight: 700;
//  @media all and (max-width: 385px) {
//    font-size: 2.25em;
//    font-size: 9.740vw;
//  }
//  @media all and (max-width: 308px) {
//    font-size: 2em;
//  }
  font-size: #{2.5em * .75};
  font-weight: 700;
  @media all and (max-width: 385px) {
    font-size: #{2.25em * .75};
    font-size: #{9.740vw * .75};
  }
  @media all and (max-width: 308px) {
    font-size: #{2em * .75};
  }
}
#error-container {
  padding: 4em 0 8em;
}
#error-inner-container {
  text-align: center;
}
.project-subtitle {
  font-weight: 500;
  line-height: 1.4;
  padding-top: 1em;
  padding-bottom: 1em;
}
.project-description-wrapper {
  padding-bottom: 1.66em;
}
.project-description {
  // display: none;
  line-height: 1.4;
  // opacity: 0;
  //  p:first-of-type {
  //    padding-top: 1em;
  //  }
  //padding-bottom: .1em;
  // padding-bottom: .66em;
  overflow: hidden;
  // max-height: 0;
  transition: max-height .8s $ease-out-quint;
  .project.active & {
  transition: max-height .8s $ease-out-quint;
    // display: block;
  //  max-height: 200px;
    // opacity: 1;
  }
}
html, body {
//  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
.no-js img.lazyload {
    display: none;
}
//  /* fade image in after load */
//  .lazyload,
//  .lazyloading {
//    opacity: 0;
//  }
//  .lazyloaded {
//    opacity: 1;
//    transition: opacity .5s $ease-out-expo .1s;
//  }
.menu-is-closed.resizing, .menu-is-open.resizing {
  #nav, #main-nav, #gigs {
    transition: all 0s $ease-out-expo 0s!important;
  }
}
#logo-container {
  width:  3.333em;
  height: 3.333em;
}
#logo {
  width: 100%;
  height: 100%;
}
canvas {
  width:100%;
  height:1em;
}
.projects-container {
  .right-column {
    display: none;
  }
  @media all and (min-width: 60em) {
    // column-count: 2;
    // column-gap: 3.5em;
    display:flex;
    flex-flow: row wrap;
    width: 100%;
    // height: 500vw;
    min-height: 500px;
    .column {
      width: calc(50% - #{3.5em * .5});
      &.left-column {
        margin-right: #{3.5em * .5}!important;
      }
      &.right-column {
        margin-left: #{3.5em * .5}!important;
        display: block;
      }
    }
  }
  //  @media all and (min-width: 60em) {
  //    display: flex;
  //    flex-flow: column nowrap;
  //    .project {
  //      &:nth-of-type(even) {
  //       // order: 2;
  //      }
  //      &:nth-of-type(odd) {
  //       // order: 1;
  //      }
  //    }
  //  }
  // display: inline-flex;
  // flex-flow: column nowrap;
  /* Force new columns */
  // height: 290em;
  // &::before {
  //   content: "";
  //   // display: block;
  //   flex-basis: 100%;
  //   width: 0;
  //   order: 2;
  // }
}
.project {
  @media all and (max-width: 60em) {
    max-width: 28em;
    margin: 3.5em auto 0;
  }
  @media all and (min-width: 60em) {
  //  -webkit-column-break-inside: avoid;
  //  page-break-inside: avoid;
  //  break-inside: avoid;
  // align-self: flex-start;
  }
    //  float: none;
  width: 100%;
  margin: 0 0 3.5em;
  // width: calc(100% - #{3.5em / 2 * 3});
  // flex: 0 1 50%;
  // &:nth-child(2n){
  //   order:1;
  // }
  // &:nth-child(2n+1){
  //   order:2;
  //   // page-break-before: always;
  //   break-before: always;
  // }
}
.article {
    @media all and (max-width: 60em) {
      width: 100%;
      float: none;
    &.even {
      display: flex;
      flex-flow: column nowrap;
      .text-container {
        order: 2;
      }
      .image-container {
        order: 1;
      }
    }
  }
}
body, a { -webkit-touch-callout: none !important; }
// li a { -webkit-user-select: none !important; }
// a:hover, a:active { -webkit-user-select: none !important; }


.external-link, .text-container li, .listenon li, .project-cover, .project:not(.active), .extend, a {
  &:hover, &:active { 
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
  }
}

//  @keyframes rotateLogo {
//    0%   { transform: rotate(0deg); }
//    50%  { transform: rotate(180deg);}
//    100%   { transform: rotate(360deg); }
//  }
//  @keyframes rotateFormA {
//    0%   { transform: translate(16px, 16px) rotate(0deg) translate(-16px, -16px) }
//    25%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
//    50%   { transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px); }
//    75%  { transform: translate(16px, 16px) rotate(270deg) translate(-16px, -16px); }
//    100%  { transform: translate(16px, 16px) rotate(360deg) translate(-16px, -16px); }
//  }
//  @keyframes rotateFormB {
//    0%   { transform: translate(36px, 36px) rotate(0deg) translate(-36px, -36px) }
//    25%  { transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
//    50%   { transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); }
//    75%  { transform: translate(36px, 36px) rotate(270deg) translate(-36px, -36px); }
//    100%  { transform: translate(36px, 36px) rotate(360deg) translate(-36px, -36px); }
//  }
@keyframes rotateHoverLogo-1 {
  0%   { transform: rotate(0deg); }
  100%  { transform: rotate(-180deg);}
}
@keyframes rotateHoverFormA-1 {
  0%   { transform: translate(16px, 16px) rotate(0deg) translate(-16px, -16px) }
  // 50%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
}
@keyframes rotateHoverFormB-1 {
  0%   { transform: translate(36px, 36px) rotate(0deg) translate(-36px, -36px) }
  100% { transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
}
@keyframes rotateHoverLogo-2 {
  0%   { transform: rotate(-180deg);}
  100% { transform: rotate(-360deg);}
}
@keyframes rotateHoverFormA-2 {
  0%   { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px) }
  // 50%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100%  { transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px); }
}
@keyframes rotateHoverFormB-2 {
  0%   { transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
  100%  { transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); }
}
@keyframes rotateHoverLogo-3 {
  0%   { transform: rotate(-180deg); }
  100%  { transform: rotate(0deg);}
}
@keyframes rotateHoverFormA-3 {
  0%   { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px) }
  // 50%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100%  { transform: translate(16px, 16px) rotate(0deg) translate(-16px, -16px); }
}
@keyframes rotateHoverFormB-3 {
  0%   { transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px) }
  100% { transform: translate(36px, 36px) rotate(0deg) translate(-36px, -36px); }
}
@keyframes rotateHoverLogo-4 {
  0%   { transform: rotate(-360deg);}
  100% { transform: rotate(-180deg);}
}
@keyframes rotateHoverFormA-4 {
  0%   { transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px) }
  // 50%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100%  { transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
}
@keyframes rotateHoverFormB-4 {
  0%   { transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); }
  100%  { transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
}

#logo-container {
  &.loading {
  &, &.hover-1 {
    #logo-group {
      pointer-events: none;
      animation: rotateHoverLogo-3 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
    .form-a {
      animation: rotateHoverFormA-3 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
    .form-b {
      animation: rotateHoverFormB-3 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
  }
  &.hover-2 {
    #logo-group {
      pointer-events: none;
      animation: rotateHoverLogo-4 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
    .form-a {
      animation: rotateHoverFormA-4 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
    .form-b {
      animation: rotateHoverFormB-4 1s ease 1;
      animation-fill-mode: forwards;
      // animation-direction: reverse;
      // animation-play-state: running;
    }
  }
  //  #logo-group {
  //    pointer-events: none;
  //    animation: rotateLogo 2.5s ease infinite!important;
  //    // animation-fill-mode: forwards;
  //  }
  //  .form-a {
  //    animation: rotateFormA 5s ease infinite!important;
  //    // animation-fill-mode: backwards;
  //  }
  //  .form-b {
  //    animation: rotateFormB 5s ease infinite!important;
  //    // animation-fill-mode: backwards;
  //  }
  }
}

#logo-container {
  &.hover-1 {
    #logo-group {
      pointer-events: none;
      animation: rotateHoverLogo-1 1s ease 1;
      animation-fill-mode: forwards;
    }
    .form-a {
      animation: rotateHoverFormA-1 1s ease 1;
      animation-fill-mode: forwards;
    }
    .form-b {
      animation: rotateHoverFormB-1 1s ease 1;
      animation-fill-mode: forwards;
    }
  }
  &.hover-2 {
    #logo-group {
      pointer-events: none;
      animation: rotateHoverLogo-2 1s ease 1;
      animation-fill-mode: forwards;
    }
    .form-a {
      animation: rotateHoverFormA-2 1s ease 1;
      animation-fill-mode: forwards;
    }
    .form-b {
      animation: rotateHoverFormB-2 1s ease 1;
      animation-fill-mode: forwards;
    }
  }
  &.loaded {
    #logo-group {
      animation-iteration-count: 0!important;
      animation-play-state: paused!important;
          animation-fill-mode: forwards;
    }
    .form-a {
      animation-iteration-count: 0!important;
      animation-play-state: paused!important;
          animation-fill-mode: forwards;
    }
    .form-b {
      animation-iteration-count: 0!important;
      animation-play-state: paused!important;
          animation-fill-mode: forwards;
    }
    &.hover-1 {
      #logo-group {
        transform: rotate(-360deg);
      }
      .form-a {
        transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px);
      }
      .form-b {
        transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px);
      }
    }
    &.hover-2 {
      #logo-group {
        transform: rotate(-180deg);
      }
      .form-a {
        transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px);
      }
      .form-b {
        transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px);
      }
    }
  }
}

//  #logo-container.hover {
//    #logo-group {
//      animation-iteration-count: 1;
//      animation-direction: reverse;
//    }
//    .form-a, .form-b {
//      animation-iteration-count: 1;
//      animation-direction: reverse;
//    }
//  }
.music, #error, .projects-header {
  width: 100%; // calc(100% - 2em);
  max-width: 28em;
  @media all and (min-width: 60em) {
    max-width: calc(100% - (100% - 28em * 2) / 4 * 2);
  }
  margin: 0 auto;
}
.music, #error {
  padding: 0 1em;
}
// .projects-header .pattern {
//   padding: 1em 0 3em;
// }
@media all and (min-width: 60em) {
  .projects-header {
    padding-bottom: 2em;
  }
}
.socialmedia {
    height: 8em;
  // max-width: 20em;
  // &.items-6 {
  //   max-width: 20em;
  // }
  // width: 100%;
  // margin: 0 auto;
  padding-bottom: 2.5em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: top;
    align-content: center;
    align-items: center;
    text-align: center;
    transform: translateX(1em);
}
.socialmedia-channel {
  text-align: center;
  // padding: 0 1em;
  &:nth-of-type(2n) {
    padding-left: 2em;
  }
&:nth-of-type(2n + 1) {
    padding-right: 2em;
  }
  // height: 4em;
  position: relative;
  // display: inline-block;
  // flex: 1 1 50%;
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: inline-block;
    width: 2em;
  }
}
.headline-container, .projects-header {
  // width: calc(100vw - 22em);
  width: 100%; // calc(100% - 2em);
  margin: 0 auto;
  .pattern {
    box-sizing: border-box;
    padding: 1em 0 3em;
  }
}
.release {
  width: 100%;
  // &:not(:last-of-type) {
    @media all and (max-width: 60em) {
      padding-bottom: 5em;
    }
    @media all and (min-width: 60em) {
      padding-bottom: 12em;
      // padding-top: calc((100vw - 28em * 2) * .25);
    }
    // padding-top: calc((100vw - 28em * 2) * .25);
  // }
} 
//  .bandcamp {
//      padding-bottom: 6.2em;
//      @media all and (min-width: 60em) {
//        padding-top: #{5em - 2.7};
//        padding-bottom: 5em;
//      padding-top: calc((100vw - 28em * 2) * .25 - 2.7em);
//      padding-bottom: calc((100vw - 28em * 2) * .25);
//      }
//  }
.project-description {
  .no-js &:target {
    display: block;
  }
}
.cover-container {
    padding: 0 1.2em 1.2em 0;
  .cover-inner-container {
        // padding: 1em;
        // padding: 0 0 1em;
        position: relative;
        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          width: calc(100% - .2em);
          height: calc(100% - .2em);
          position: absolute;
          top: 1em;
          left: 1em;
          border: $border solid $color;
          z-index: -1;
        }
        @media all and (min-width: 60em) {
        // padding: 0;
          max-width: 27.8em;
        }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.cover-container, .listenon-container, .info-container {
    width: 100%;
  //  @media all and (min-width: 60em) {
  //    width: 50%;
  //  }
  .cover-inner-container, .info-inner-container {
    width: 100%;
  }
}
.info-container {
  .info-inner-container {
    width: 100%;
    // padding: 0 0 1em;
    // padding: 1em;
    // margin: 0 auto 0 0;
    @media all and (min-width: 60em) {
      padding: 0 0 0 1em;
    }
    // @media all and (min-width: 60em) {
      //max-width: 40em;
    // }
    margin: 0 auto;
    h3 {
      font-size: #{2.5em * .75};
      font-weight: 700;
      @media all and (max-width: 385px) {
        font-size: #{2.25em * .75};
        font-size: #{9.740vw * .75};
      }
      @media all and (max-width: 308px) {
        font-size: #{2em * .75};
      }
      // font-weight: 700;
      padding-top: 1em;
      padding-bottom: .133em;
      margin-left: -.1em;
      @media all and (min-width: 60em) {
        margin-top: -.125em;
      }
    }
    h4 {
      &:after {
        content: '\00a0\00a0\2022\00a0\00a0';
        font-family: sans-serif;
        display: inline-block;
        transform: translateY(.1em);
      }
    }
    h4, h5 {
      font-weight: 500;
    }
    .order, .time {
      font-variant-numeric: tabular-nums;
    }
    .tracklist {
      list-style-type: none;
      .track {
        border-top: .1em solid $color;
        padding: .4em 0 .5em;
        &:first-child {
          margin-top: 2em;
        }
        &:last-child {
          border-bottom: .1em solid $color;
        }
      }
      .justify {
        line-height: 1.4;
        display: flex;
        flex-flow: row nowrap;
        //justify-content: space-between;
        align-items: flex-start;
        .order {
          flex: 0 0 2em;
        }
        .title {
          flex: 1 0 auto;
          max-width: calc(100% - 5em);
          text-overflow: ellipsis;
        }
        .time {
          flex: 0 1 3em;
          align-self: flex-start;
        }
      }
    }
  }
}
.extend-icon {
  pointer-events: none;
  .project.active & {
    .plus {
      display: none;
    }
  }
}
.project:not(.active) {
  cursor: pointer;
  &:hover .extend  {
    line {
      stroke: $background;
    }
    circle {
      fill: $color;
    }
  }
}
.extend {
  width: 3em;
  height: 3em;
  // background: rgba(255,0,0,.5);
  position: absolute;
  right: .8em;
  bottom: -1.6em;
  &:hover {
    line {
      stroke: $background;
    }
    circle {
      fill: $color;
    }
  }
}
.external-link-icon {
  position: absolute;
  bottom: 1em;
  right: .6em;
  width: 1.66em;
  height: auto;
}

@media all and (min-width: 60em) {
  .release {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: min-content max-content;
    grid-template-areas: "left-1 right" "left-2 right";
  }
  
  .cover-container { grid-area: left-1; }
  
  .info-container { grid-area: right;}
}
.listenon-container { grid-area: left-2;}

// correct the error page
#main-section-content {
  display: flex;
  flex-flow: row wrap;
  /* align-items: flex-end; */
  /* justify-content: unset; */
  align-content: space-between;
}
#error {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}

// text selection on future gigs

.gig-wrapper {
  h2, h3, h4, h5 {
    &::selection {
      color: $color;
      background-color: rgba(0,0,0,.15);
    }
  }
}
@media all and (max-width: 30em) {
  .article.projects {
    &:not(:first-of-type) {
      .projects-header {
        padding-top: .5em;
      }
    }
  }
}
@media all and (min-width: 60em) {
  .article.projects {
    &:not(:first-of-type) {
        padding-top: 0em;
    }
  }
}