@charset "UTF-8";
@import url("https://p.typekit.net/p.css?s=1&k=tmo3ugh&ht=tk&f=26053.26054.26056.26060.26061.26062&a=795339&app=typekit&e=css");
@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/829fc1/00000000000000003b9acaf8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-style: normal;
  font-weight: 500; }

/* variables */
/* variables */
/* reset */
html {
  box-sizing: border-box; }

* {
  box-sizing: inherit; }

* {
  margin: 0;
  padding: 0; }

article, header, nav, section {
  display: block; }

h1, h2, h3, h4 {
  font-size: inherit;
  font-weight: inherit; }

a {
  text-decoration: inherit;
  color: inherit; }

/* browser defaults */
::selection {
  color: #111;
  background-color: rgba(0, 0, 0, 0); }

/* browser defaults */
#main-section h2::selection, #main-section h3::selection, #main-section h4::selection, #main-section h5::selection, #main-section p::selection, #main-section span::selection, #main-section p a::selection, .past-gig h2::selection, .past-gig h3::selection, .past-gig h4::selection, .past-gig h5::selection, .past-gig p::selection, .past-gig span::selection, .past-gig p a::selection {
  color: #111;
  background-color: rgba(0, 0, 0, 0.15); }

#main-section.luke-le-loup h2::selection, #main-section.luke-le-loup h3::selection, #main-section.luke-le-loup h4::selection, #main-section.luke-le-loup h5::selection, #main-section.luke-le-loup p::selection, #main-section.luke-le-loup span::selection, #main-section.luke-le-loup p a::selection, .past-gig.luke-le-loup h2::selection, .past-gig.luke-le-loup h3::selection, .past-gig.luke-le-loup h4::selection, .past-gig.luke-le-loup h5::selection, .past-gig.luke-le-loup p::selection, .past-gig.luke-le-loup span::selection, .past-gig.luke-le-loup p a::selection {
  background-color: #d0f7ee;
  background-image: linear-gradient(90deg, #AEF3FC, #DEFFDE); }

#main-section.bestboi h2::selection, #main-section.bestboi h3::selection, #main-section.bestboi h4::selection, #main-section.bestboi h5::selection, #main-section.bestboi p::selection, #main-section.bestboi span::selection, #main-section.bestboi p a::selection, .past-gig.bestboi h2::selection, .past-gig.bestboi h3::selection, .past-gig.bestboi h4::selection, .past-gig.bestboi h5::selection, .past-gig.bestboi p::selection, .past-gig.bestboi span::selection, .past-gig.bestboi p a::selection {
  background-color: #e3e3fd;
  background-image: linear-gradient(90deg, #C9E6FF, #FFDEFF); }

#main-section.projects h2::selection, #main-section.projects h3::selection, #main-section.projects h4::selection, #main-section.projects h5::selection, #main-section.projects p::selection, #main-section.projects span::selection, #main-section.projects p a::selection, .past-gig.projects h2::selection, .past-gig.projects h3::selection, .past-gig.projects h4::selection, .past-gig.projects h5::selection, .past-gig.projects p::selection, .past-gig.projects span::selection, .past-gig.projects p a::selection {
  background-color: #fbe3ca;
  background-image: linear-gradient(90deg, #FFF6CE, #FFCDBE); }

a, a:hover, a:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

a img {
  border: 0; }

/* helper classes */
.clearfix::after {
  content: '';
  display: block;
  clear: both; }

.floated {
  float: left; }

.unselectable {
  user-select: none; }

.ignore {
  pointer-events: none; }

.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

/* typography */
html {
  font-size: 15px; }

ul {
  font-size: 0;
  line-height: 0; }
  ul li {
    font-size: 15px;
    line-height: 1; }

body {
  font-size: 1em;
  font-family: 'acumin-pro', sans-serif;
  line-height: 1; }

h1, h2, h3, h4 {
  font-weight: 700;
  font-size: 1em; }

h5 {
  font-weight: 500;
  font-size: 1em; }

/* color */
html, body {
  color: #111; }

#main {
  background-color: #fff; }

#loading-screen {
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, visibility 0s linear 1s;
  visibility: hidden; }
  #loading-screen #body:not(.loading) {
    z-index: -1; }
  #body.menu-is-open #loading-screen {
    opacity: .85; }
  #body.loading #loading-screen {
    visibility: visible;
    opacity: 1; }

/* header */
#header {
  width: 100%;
  height: 3.7em;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  border-bottom: 0.2em solid #111;
  padding: .2em .5em;
  z-index: 5;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transform: translateY(0); }

.menu-is-open #main-nav {
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transform: translateX(0); }

@media all and (min-width: 60em) {
  .menu-is-closed #main-nav {
    transform: translateX(-101%) !important; } }

@media all and (min-width: 60em) {
  .menu-is-closed #gigs {
    transform: translateX(101%) !important; }
  .menu-is-open #gigs {
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transform: translateX(0); } }

#body.menu-is-closed #main-section-content {
  opacity: 1;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  background: #fff;
  transform: translateY(0); }

.click-response {
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1); }

#logo.active .click-response-form-a {
  transform: translate(0.125em, 0.125em); }

#logo.active .click-response-form-b {
  transform: translate(-0.125em, -0.125em); }

#logo.active .click-response-form-c {
  transform: translate(-0.125em, 0.125em); }

#logo.active .click-response-form-d {
  transform: translate(0.125em, -0.125em); }

#headline {
  padding-bottom: .1em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center; }

#email {
  padding-bottom: .3em; }

#email {
  font-size: 1.75em; }

/* main */
#main {
  padding-top: 3.5em;
  width: 100%;
  overflow-x: hidden; }
  #main h2 {
    font-size: 2.5em;
    font-weight: 700; }
    @media all and (max-width: 385px) {
      #main h2 {
        font-size: 2.25em;
        font-size: 9.740vw; } }
    @media all and (max-width: 308px) {
      #main h2 {
        font-size: 2em; } }

#nav {
  top: 0;
  position: absolute;
  z-index: 3; }

#gigs-header {
  display: table;
  width: 100%;
  min-height: 5em;
  height: calc(25vh - 5em);
  height: calc(var(--vh, 1vh) * 25 - 5em);
  max-height: 150px;
  border-bottom: 0.2em solid #111; }
  @media all and (max-width: 60em), all and (orientation: portrait) {
    #gigs-header {
      max-height: 7em; } }
  #gigs-header h2 {
    position: relative;
    display: inline-block;
    display: table-cell;
    vertical-align: middle; }

#gigs-nav {
  height: 2.5em;
  padding: 0 3.5em 0;
  position: -webkit-sticky;
  position: sticky;
  -webkit-transform: translateZ(0);
  top: 0;
  z-index: 4;
  background-color: #fff;
  border-bottom: 0.2em solid #111; }
  @media all and (max-width: 385px) {
    #gigs-nav {
      padding: 0 13.636vw 0; } }
  #gigs-nav > * {
    -webkit-transform: translateZ(0); }
  #gigs-nav ul {
    padding: 0 3.5em 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    padding: 3; }
    @media all and (max-width: 385px) {
      #gigs-nav ul {
        padding: 0 13.636vw 0; } }
    #gigs-nav ul li {
      font-weight: 700;
      list-style-type: none;
      display: inline-block;
      padding-bottom: .3em; }
      #gigs-nav ul li:not(:last-of-type) {
        padding-right: 6em; }

#main-section-header nav, #main-section-header ul, #main-section-header a, #gigs-nav nav, #gigs-nav ul, #gigs-nav a {
  height: 100%; }

#main-section-header li, #gigs-nav li {
  display: inline-block;
  opacity: .3; }
  #main-section-header li.active, #gigs-nav li.active {
    opacity: 1; }

/* footer */
/* pattern */
.pattern {
  height: 1em;
  width: 100%; }
  #nav .pattern {
    bottom: -1.7em;
    min-width: 10em;
    position: absolute;
    right: -.3em;
    width: 30%;
    z-index: 3; }
  #main-nav .pattern {
    bottom: 1.5em;
    min-width: 10em;
    position: absolute;
    right: 2.6em;
    transition: right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 30%;
    z-index: 3; }
    @media all and (max-width: 30em) {
      #main-nav .pattern {
        right: 0.8em; } }

/* home/gigs */
.gig .gig-cover, .gig::before {
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  will-change: transform; }

.external-link:hover .gig-cover {
  transform: translateY(0.5em); }

.external-link:hover .gig:before {
  transform: translate(-1em, 0.5em) !important; }

#gigs h2 {
  text-align: center; }

#gigs .gig {
  position: relative; }

#gigs .gig {
  padding: 1em; }
  #gigs .gig::before {
    content: ' ';
    height: 100%;
    height: calc(100%  + 0.2em);
    left: -0.2em;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    transform: translate(-1em, 1em);
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    width: calc(100% + 0.4em + 2em);
    z-index: -1; }

#gigs .gig-cover {
  position: relative;
  top: -2.2em;
  margin-bottom: -2.2em; }

#gigs .gig.luke-le-loup::before {
  background-image: linear-gradient(90deg, #AEF3FC, #DEFFDE); }

#gigs .gig.bestboi::before {
  background-image: linear-gradient(90deg, #C9E6FF, #FFDEFF); }

#gigs .gig.projects::before {
  background-image: linear-gradient(90deg, #FFF6CE, #FFCDBE); }

#gigs .gig-cover {
  width: 100%; }

#gigs .gig-date {
  float: left;
  padding-right: .8em; }
  #gigs .gig-date span {
    display: block; }

#gigs .gig-wrapper {
  display: flex;
  flex-flow: row nowrap;
  padding-top: 1em; }
  #gigs .gig-wrapper, #gigs .gig-wrapper * {
    pointer-events: auto;
    user-select: auto; }
    #gigs .gig-wrapper:selection, #gigs .gig-wrapper *:selection {
      background-color: red;
      color: green; }

#gigs .gig-date-month {
  text-transform: uppercase; }

#gigs .gig-date-month, #gigs .gig-date-year {
  text-align: center; }

#gigs .gig-date-day {
  font-size: 2.5em;
  font-weight: 700; }
  @media all and (max-width: 385px) {
    #gigs .gig-date-day {
      font-size: 2.25em;
      font-size: 9.740vw; } }
  @media all and (max-width: 308px) {
    #gigs .gig-date-day {
      font-size: 2em; } }

#gigs .gig-title {
  font-size: 1.875em;
  padding-top: .25em;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  min-height: 1em;
  font-weight: 700; }
  @media all and (max-width: 385px) {
    #gigs .gig-title {
      font-size: 1.6875em;
      font-size: 7.305vw; } }
  @media all and (max-width: 308px) {
    #gigs .gig-title {
      font-size: 1.5em; } }

#gigs .gig-location {
  padding-top: 1.13em;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

#main-nav {
  padding-top: 2.3em; }
  #main-nav li {
    display: table;
    height: calc(25vh - 1.5em);
    height: calc(var(--vh, 1vh) * 25 - 1.5em);
    max-height: 150px;
    max-height: calc(150px + 3.2em);
    position: relative;
    width: 100%;
    padding: 0 3.5em 3em 3.5em;
    transition: padding 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    @media all and (max-width: 30em) {
      #main-nav li {
        padding: 0 1em 3em 1em; } }
    @media all and (max-width: 60em), all and (orientation: portrait) {
      #main-nav li {
        max-height: 10em; } }
  #main-nav a {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0.2em solid #111;
    padding-left: 1.8em;
    padding-bottom: 0.2em;
    display: table; }
    #main-nav a span {
      display: table-cell;
      vertical-align: middle;
      font-size: 2.5em;
      font-weight: 700; }
      @media all and (max-width: 385px) {
        #main-nav a span {
          font-size: 2.25em;
          font-size: 9.740vw; } }
      @media all and (max-width: 308px) {
        #main-nav a span {
          font-size: 2em; } }
  #main-nav li a::before {
    content: ' ';
    width: 100%;
    width: calc(100% + 0.2em);
    height: 100%;
    height: calc(100%  + 0.2em);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(1em, 1em);
    will-change: transform;
    z-index: -1;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
  #main-nav li a.hover::before, #main-nav li a:hover::before {
    transform: translate(0.5em, 0.5em); }
  #main-nav li.luke-le-loup a::before {
    background-image: linear-gradient(90deg, #AEF3FC, #DEFFDE); }
  #main-nav li.bestboi a::before {
    background-image: linear-gradient(90deg, #C9E6FF, #FFDEFF); }
  #main-nav li.projects a::before {
    background-image: linear-gradient(90deg, #FFF6CE, #FFCDBE); }

.gig-date-and-title {
  display: flex; }

@media all and (max-width: 60em) {
  #nav {
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 3.5em;
    left: 0;
    width: 100%;
    height: 100%;
    height: calc(100% - 3.5em); }
  #gigs {
    border-top: 0.2em solid #111; } }

#main-nav, #gigs {
  width: 100%; }

@media all and (min-width: 60em) {
  #main-nav, #gigs {
    background-color: #fff; }
  #main-nav {
    border-right: 0.2em solid #111;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    width: calc(50% + .1em);
    height: 100%;
    overflow: auto; }
  #gigs {
    border-left: 0.2em solid #111;
    position: fixed;
    left: 50%;
    left: calc(50% - .1em);
    top: 0;
    width: 50%;
    width: calc(50% + .1em);
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain; } }

.gig {
  border: 0.2em solid #111;
  margin: 3.5em;
  transition: margin 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  @media all and (max-width: 385px) {
    .gig {
      margin: 13.636vw; } }
  @media all and (max-width: 30em) {
    .gig {
      margin: 3.5em 1em; } }

.gig-title {
  mix-blend-mode: multiply; }

.gig-cover-container {
  position: relative; }

.gig-cover {
  z-index: 2; }

.menu-item-link {
  min-height: 5.2em; }

.menu-item.active a::before {
  transform: translate(0, 0) !important; }

.gig.active .gig-cover {
  transform: translateX(2.2em); }

.gig.active::before {
  transform: translate(0em, 0em) !important; }

.gig.active.pattern {
  width: 100%; }

canvas {
  position: relative; }

body > .p5Canvas {
  display: none; }

#main-nav, #gigs {
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1); }

p + a, .footer a {
  text-decoration: underline; }

.footer {
  width: 100%;
  display: table;
  text-align: center;
  border-top: 0.2em solid #111;
  height: calc(25vh - -2.575em);
  height: calc(var(--vh, 1vh) * 25 - -1.85em);
  max-height: 150px;
  max-height: calc(150px);
  padding: 3em 0 3.5em 0;
  position: relative; }
  .footer:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2em;
    transform: translateY(0.2em);
    background-color: #111; }

.footer-wrapper {
  display: table-cell;
  vertical-align: middle;
  letter-spacing: .03em;
  font-size: 0.8em !important;
  line-height: 1.6; }

#headline {
  height: 3.4em; }

.menu-is-open .article {
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: .85; }

.menu-is-open #title {
  transform: translateY(1.125em) scale(1);
  letter-spacing: 0em; }

.menu-is-open #subtitle {
  position: relative;
  z-index: -1;
  opacity: 0; }

#title, #subtitle {
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1); }

@media all and (max-width: 60em) {
  #nav {
    transition: border-color 0s linear 1s, transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    border-top: 0.2em solid #111; } }

.menu-is-closed {
  overflow-x: hidden; }
  @media all and (max-width: 60em) {
    .menu-is-closed #nav {
      transform: translateY(0); } }
  .menu-is-closed #title {
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), letter-spacing 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .menu-is-closed #subtitle {
    transform: translateY(0.6em); }
  .menu-is-closed #title {
    transform: translateY(0.6em) scale(0.75);
    letter-spacing: .05em; }
  .menu-is-closed #subtitle {
    opacity: 1; }
  .menu-is-closed #nav, .menu-is-closed #main-nav, .menu-is-closed #gigs {
    pointer-events: none; }
  @media all and (max-width: 60em) {
    .menu-is-closed #nav {
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.25s;
      transform: translateY(101%); } }
  @media all and (min-width: 60em) {
    .menu-is-closed #main-nav {
      transform: translateX(-101%); } }
  @media all and (min-width: 60em) {
    .menu-is-closed #gigs {
      transform: translateX(101%); } }

h1, h2, h3, h4, h5, p, li {
  cursor: default; }

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

#main-section {
  position: relative;
  z-index: 1; }

#main-section-content {
  position: fixed;
  top: 3.7em;
  left: 0;
  height: calc(100% - 3.7em);
  padding-top: 2.5em;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  width: 100%;
  z-index: 1; }

#main-section-header {
  z-index: 2;
  position: fixed;
  top: 3.7em;
  left: 0;
  right: 0;
  width: 100%;
  height: 2.5em;
  max-width: 1800px;
  margin: 0 auto;
  background-color: #fff;
  background-size: 200% 100%;
  background-size: 200vw 100%;
  text-align: center;
  border-bottom: 0.2em solid #111;
  font-weight: 700;
  animation: gradient-animation 5s linear infinite; }
  #main-section.luke-le-loup #main-section-header {
    background-image: linear-gradient(-90deg, #AEF3FC, #DEFFDE); }
  #main-section.bestboi #main-section-header {
    background-image: linear-gradient(-90deg, #C9E6FF, #FFDEFF); }
  #main-section.projects #main-section-header {
    background-image: linear-gradient(-90deg, #FFF6CE, #FFCDBE); }
  .menu-is-open #main-section-header {
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    transform: translateY(-2.5em);
    animation-play-state: paused; }
  .menu-is-closed #main-section-header {
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.25s;
    transform: translateY(0);
    animation-play-state: running; }
  #main-section-header .main-section-header-3-items .main-section-header-item {
    text-align: center; }
    #main-section-header .main-section-header-3-items .main-section-header-item:first-of-type {
      text-align: right;
      padding-right: 1.5em; }
    #main-section-header .main-section-header-3-items .main-section-header-item:last-of-type {
      text-align: left;
      padding-left: 1.5em; }
  #main-section-header li {
    width: 25%;
    max-width: 12em; }
  #main-section-header a {
    display: inline-block;
    padding: .6em .5em .7em; }

.text-inner-container {
  width: 100%;
  padding: 1em 1em 6em;
  max-width: 28em;
  margin: auto; }
  @media all and (min-width: 60em) {
    .text-inner-container {
      padding: 1em; } }

@media all and (min-width: 60em) {
  .listenon-container {
    padding-top: 2.2em; } }

@media all and (max-width: 60em) {
  .listenon-container {
    padding-left: 3.3em; } }

@media all and (min-width: 60em) {
  .listenon-container {
    padding-left: 4.3em; } }

.listenon {
  position: relative; }
  .listenon li {
    position: relative;
    width: 100%;
    max-width: 24.7em; }
    .listenon li img {
      width: 2.4em;
      height: 2.4em;
      position: absolute;
      top: 0;
      left: -3.5em; }
    .listenon li:first-of-type {
      margin-top: 0; }
    @media all and (max-width: 60em) {
      .listenon li:last-of-type {
        margin-bottom: 2em; } }

.info-inner-container {
  padding-bottom: 2.2em; }

.releases-inner-container {
  padding-bottom: 3em; }

.text-container li, .listenon li {
  list-style-type: none;
  font-weight: 700;
  width: 100%;
  height: 2.7em;
  text-align: center;
  border: 0.2em solid #111;
  margin-top: 1em; }
  .text-container li a, .listenon li a {
    padding: .5em 0 .5em;
    width: 100%;
    height: 100%;
    display: inline-block; }
  .text-container li:hover, .listenon li:hover {
    background-color: #111;
    color: #fff; }

.text-container li:first-child {
  margin-top: 3.5em; }

@media all and (max-width: 60em) {
  .text-container {
    padding-bottom: 1em;
    width: 100%; } }

@media all and (min-width: 60em) {
  .text-container {
    width: calc(50% - .1em); } }

.text-container p {
  letter-spacing: .02em;
  line-height: 1.4; }

.text-container .pattern {
  box-sizing: border-box;
  padding: 1em 0 2.6em; }

@media all and (min-width: 60em) {
  .text-container {
    padding: 3em 1em; }
    .text-container .pattern {
      width: 100%; } }

.image-container {
  width: 100%;
  transform: translateY(-1.2em); }
  @media all and (min-width: 60em) {
    .image-container {
      padding-bottom: 5em; } }
  .image-container img {
    width: auto; }
  @media all and (max-width: 60em) {
    .image-container {
      float: none; } }
  .image-container .image-inner-container {
    height: calc(((100vw - 26em) * .5 + 26.1em) * .8 - .2em); }
    @media all and (max-width: 60em) {
      .image-container .image-inner-container {
        position: relative;
        width: calc(100% + .4em);
        max-height: calc(80vw + .2em);
        max-width: calc((100vw - 26em) * .5 + 26.1em); } }
    @media all and (min-width: 60em) {
      .image-container .image-inner-container {
        height: calc((50vw) * .8); } }
    @media all and (min-width: 1800px) {
      .image-container .image-inner-container {
        height: 720px; } }
  .image-container img {
    width: 100%;
    height: auto; }
  .article.even .image-container img {
    transform: translate(1em, 1em); }
  @media all and (max-width: 60em) {
    .article.even .image-container {
      margin-left: -0.2em; }
      .article.even .image-container .image-inner-container {
        margin-left: auto;
        margin-right: -.4em; } }
  @media all and (min-width: 60em) {
    .article.even .image-container {
      width: calc(50% - .7em); } }
  .article.odd .image-container .image-inner-container {
    margin-left: -.2em; }
  .article.odd .image-container img {
    transform: translate(-1em, 1em); }
  @media all and (min-width: 60em) {
    .article.odd .image-container {
      width: calc(50% - .7em); } }

.image-container img {
  mix-blend-mode: multiply; }

.image-inner-container {
  width: calc(100% + 1em);
  position: relative;
  border: 0.2em solid #111; }

.js .gigs:not(.active) {
  display: none; }

.no-js .gigs {
  display: none; }

.no-js .gigs:target {
  display: block; }

.menu-item-link *, #logo *, .gig * {
  pointer-events: none; }

.past-gigs-header {
  padding: 3.2em 0 .8em;
  border-bottom: 0.2em solid #111; }

#past-gigs {
  padding-bottom: 5.5em; }
  #past-gigs h2 {
    text-align: left;
    font-size: 1.5em; }
  #past-gigs h3 {
    font-weight: 500; }
  #past-gigs article {
    line-height: 1.4;
    padding: .5em 0 1em;
    border-bottom: 0.1em solid #111; }
  #past-gigs section {
    padding: 0 3.5em;
    transition: padding 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    @media all and (max-width: 30em) {
      #past-gigs section {
        padding: 0 1em; } }

@media all and (min-width: 1800px) {
  body {
    transform: translateX(0px); } }

@media all and (min-width: 1800px) {
  body:before {
    content: ' ';
    display: block;
    height: 100%;
    width: .2em;
    background: #111;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999; }
  body:after {
    content: ' ';
    display: block;
    height: 100%;
    width: .2em;
    background: #111;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999; } }

body {
  max-width: 1806px;
  max-width: calc(1800px + .4em);
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
  background-color: #fff; }

@media all and (min-width: 60em) {
  #nav {
    overflow: hidden;
    width: 100%;
    top: 3.7em;
    height: calc(100% - 3.7em); } }

@media all and (min-width: 60em) {
  #main-nav, #gigs {
    position: absolute;
    top: 0; } }

html {
  position: relative;
  background-color: #fff;
  background-size: 200% 200%;
  animation: gradient-animation 5s linear infinite;
  width: 100%;
  height: 100%; }
  html.current-page--luke-le-loup {
    background-image: linear-gradient(-90deg, #AEF3FC, #DEFFDE); }
  html.current-page--bestboi {
    background-image: linear-gradient(-90deg, #C9E6FF, #FFDEFF); }
  html.current-page--projects {
    background-image: linear-gradient(-90deg, #FFF6CE, #FFCDBE); }

#main-nav {
  overflow: auto; }

#main-section-content {
  max-width: 1800px;
  margin: 0 auto;
  right: 0; }

@media all and (min-width: 60em) {
  #main-nav, #gigs {
    max-width: 902px; } }

#main-nav {
  right: 50%;
  margin-left: auto; }
  @media all and (min-width: 60em) {
    #main-nav {
      right: calc(50% - .1em); } }

#gigs {
  left: 50%;
  margin-right: auto; }
  @media all and (min-width: 60em) {
    #gigs {
      left: calc(50% - .1em); } }

.projects {
  padding: 3em 3em 0em;
  transition: padding 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .projects .projects-container {
    padding: 0 0 0; }
  @media all and (max-width: 390px) {
    .projects .projects-container {
      padding: 3em 0 0; } }
  @media all and (max-width: 30em) {
    .projects {
      padding: 3em 1em 0; } }
  @media all and (min-width: 60em) {
    .projects {
      padding: 3em 0 0; }
      .projects .projects-container {
        padding: 0 3.5em 0; } }
  .projects:last-of-type {
    padding-bottom: 7em; }
  .projects .pattern {
    padding: 1em 0; }

.project {
  width: 50%;
  width: calc(50% - 5.25em);
  padding: 1em 1em 0;
  position: relative;
  border: 0.2em solid #111;
  margin: 3.5em 0 0 3.5em; }
  .project:not(.active) *:not(.extend) {
    pointer-events: none; }
  .project li {
    list-style-type: none;
    font-weight: 700;
    width: 100%;
    height: 2.7em;
    text-align: center;
    border: 0.2em solid #111;
    margin: 1em auto 0; }
    .project li:first-child {
      margin: 3.5em auto 0; }
    .project li a {
      padding: .5em;
      width: 100%;
      height: 100%;
      display: inline-block; }
      .project li a:hover {
        background-color: #111;
        color: #fff; }

.project-cover-container {
  position: relative;
  padding-bottom: 1em; }

.project-cover {
  position: relative;
  margin-bottom: -2.2em;
  transform: translateY(-2.2em);
  width: 100%;
  z-index: 0;
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform; }
  .project.active .project-cover, .project:not(.active):hover .project-cover {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateY(-1.7em); }

.project-title, #error-inner-container {
  font-size: 1.875em;
  font-weight: 700; }
  @media all and (max-width: 385px) {
    .project-title, #error-inner-container {
      font-size: 1.6875em;
      font-size: 7.305vw; } }
  @media all and (max-width: 308px) {
    .project-title, #error-inner-container {
      font-size: 1.5em; } }

#error-container {
  padding: 4em 0 8em; }

#error-inner-container {
  text-align: center; }

.project-subtitle {
  font-weight: 500;
  line-height: 1.4;
  padding-top: 1em;
  padding-bottom: 1em; }

.project-description-wrapper {
  padding-bottom: 1.66em; }

.project-description {
  line-height: 1.4;
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0.23, 1, 0.32, 1); }
  .project.active .project-description {
    transition: max-height 0.8s cubic-bezier(0.23, 1, 0.32, 1); }

html, body {
  -webkit-backface-visibility: hidden; }

.no-js img.lazyload {
  display: none; }

.menu-is-closed.resizing #nav, .menu-is-closed.resizing #main-nav, .menu-is-closed.resizing #gigs, .menu-is-open.resizing  #nav, .menu-is-open.resizing  #main-nav, .menu-is-open.resizing  #gigs {
  transition: all 0s cubic-bezier(0.19, 1, 0.22, 1) 0s !important; }

#logo-container {
  width: 3.333em;
  height: 3.333em; }

#logo {
  width: 100%;
  height: 100%; }

canvas {
  width: 100%;
  height: 1em; }

.projects-container {
  /* Force new columns */ }
  .projects-container .right-column {
    display: none; }
  @media all and (min-width: 60em) {
    .projects-container {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      min-height: 500px; }
      .projects-container .column {
        width: calc(50% - 1.75em); }
        .projects-container .column.left-column {
          margin-right: 1.75em !important; }
        .projects-container .column.right-column {
          margin-left: 1.75em !important;
          display: block; } }

.project {
  width: 100%;
  margin: 0 0 3.5em; }
  @media all and (max-width: 60em) {
    .project {
      max-width: 28em;
      margin: 3.5em auto 0; } }

@media all and (max-width: 60em) {
  .article {
    width: 100%;
    float: none; }
    .article.even {
      display: flex;
      flex-flow: column nowrap; }
      .article.even .text-container {
        order: 2; }
      .article.even .image-container {
        order: 1; } }

body, a {
  -webkit-touch-callout: none !important; }

.external-link:hover, .external-link:active, .text-container li:hover, .text-container li:active, .listenon li:hover, .listenon li:active, .project-cover:hover, .project-cover:active, .project:not(.active):hover, .project:not(.active):active, .extend:hover, .extend:active, a:hover, a:active {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important; }

@keyframes rotateHoverLogo-1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-180deg); } }

@keyframes rotateHoverFormA-1 {
  0% {
    transform: translate(16px, 16px) rotate(0deg) translate(-16px, -16px); }
  100% {
    transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); } }

@keyframes rotateHoverFormB-1 {
  0% {
    transform: translate(36px, 36px) rotate(0deg) translate(-36px, -36px); }
  100% {
    transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); } }

@keyframes rotateHoverLogo-2 {
  0% {
    transform: rotate(-180deg); }
  100% {
    transform: rotate(-360deg); } }

@keyframes rotateHoverFormA-2 {
  0% {
    transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100% {
    transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px); } }

@keyframes rotateHoverFormB-2 {
  0% {
    transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
  100% {
    transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); } }

@keyframes rotateHoverLogo-3 {
  0% {
    transform: rotate(-180deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes rotateHoverFormA-3 {
  0% {
    transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }
  100% {
    transform: translate(16px, 16px) rotate(0deg) translate(-16px, -16px); } }

@keyframes rotateHoverFormB-3 {
  0% {
    transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }
  100% {
    transform: translate(36px, 36px) rotate(0deg) translate(-36px, -36px); } }

@keyframes rotateHoverLogo-4 {
  0% {
    transform: rotate(-360deg); }
  100% {
    transform: rotate(-180deg); } }

@keyframes rotateHoverFormA-4 {
  0% {
    transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px); }
  100% {
    transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); } }

@keyframes rotateHoverFormB-4 {
  0% {
    transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); }
  100% {
    transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); } }

#logo-container.loading #logo-group, #logo-container.loading.hover-1 #logo-group {
  pointer-events: none;
  animation: rotateHoverLogo-3 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loading .form-a, #logo-container.loading.hover-1 .form-a {
  animation: rotateHoverFormA-3 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loading .form-b, #logo-container.loading.hover-1 .form-b {
  animation: rotateHoverFormB-3 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loading.hover-2 #logo-group {
  pointer-events: none;
  animation: rotateHoverLogo-4 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loading.hover-2 .form-a {
  animation: rotateHoverFormA-4 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loading.hover-2 .form-b {
  animation: rotateHoverFormB-4 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-1 #logo-group {
  pointer-events: none;
  animation: rotateHoverLogo-1 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-1 .form-a {
  animation: rotateHoverFormA-1 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-1 .form-b {
  animation: rotateHoverFormB-1 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-2 #logo-group {
  pointer-events: none;
  animation: rotateHoverLogo-2 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-2 .form-a {
  animation: rotateHoverFormA-2 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.hover-2 .form-b {
  animation: rotateHoverFormB-2 1s ease 1;
  animation-fill-mode: forwards; }

#logo-container.loaded #logo-group {
  animation-iteration-count: 0 !important;
  animation-play-state: paused !important;
  animation-fill-mode: forwards; }

#logo-container.loaded .form-a {
  animation-iteration-count: 0 !important;
  animation-play-state: paused !important;
  animation-fill-mode: forwards; }

#logo-container.loaded .form-b {
  animation-iteration-count: 0 !important;
  animation-play-state: paused !important;
  animation-fill-mode: forwards; }

#logo-container.loaded.hover-1 #logo-group {
  transform: rotate(-360deg); }

#logo-container.loaded.hover-1 .form-a {
  transform: translate(16px, 16px) rotate(180deg) translate(-16px, -16px); }

#logo-container.loaded.hover-1 .form-b {
  transform: translate(36px, 36px) rotate(180deg) translate(-36px, -36px); }

#logo-container.loaded.hover-2 #logo-group {
  transform: rotate(-180deg); }

#logo-container.loaded.hover-2 .form-a {
  transform: translate(16px, 16px) rotate(90deg) translate(-16px, -16px); }

#logo-container.loaded.hover-2 .form-b {
  transform: translate(36px, 36px) rotate(90deg) translate(-36px, -36px); }

.music, #error, .projects-header {
  width: 100%;
  max-width: 28em;
  margin: 0 auto; }
  @media all and (min-width: 60em) {
    .music, #error, .projects-header {
      max-width: calc(100% - (100% - 28em * 2) / 4 * 2); } }

.music, #error {
  padding: 0 1em; }

@media all and (min-width: 60em) {
  .projects-header {
    padding-bottom: 2em; } }

.socialmedia {
  height: 8em;
  padding-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: top;
  align-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(1em); }

.socialmedia-channel {
  text-align: center;
  position: relative; }
  .socialmedia-channel:nth-of-type(2n) {
    padding-left: 2em; }
  .socialmedia-channel:nth-of-type(2n + 1) {
    padding-right: 2em; }
  .socialmedia-channel img {
    width: 100%;
    height: auto; }
  .socialmedia-channel a {
    display: inline-block;
    width: 2em; }

.headline-container, .projects-header {
  width: 100%;
  margin: 0 auto; }
  .headline-container .pattern, .projects-header .pattern {
    box-sizing: border-box;
    padding: 1em 0 3em; }

.release {
  width: 100%; }
  @media all and (max-width: 60em) {
    .release {
      padding-bottom: 5em; } }
  @media all and (min-width: 60em) {
    .release {
      padding-bottom: 12em; } }

.no-js .project-description:target {
  display: block; }

.cover-container {
  padding: 0 1.2em 1.2em 0; }
  .cover-container .cover-inner-container {
    position: relative; }
    .cover-container .cover-inner-container:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      width: calc(100% - .2em);
      height: calc(100% - .2em);
      position: absolute;
      top: 1em;
      left: 1em;
      border: 0.2em solid #111;
      z-index: -1; }
    @media all and (min-width: 60em) {
      .cover-container .cover-inner-container {
        max-width: 27.8em; } }
  .cover-container img {
    width: 100%;
    height: auto; }

.cover-container, .listenon-container, .info-container {
  width: 100%; }
  .cover-container .cover-inner-container, .cover-container .info-inner-container, .listenon-container .cover-inner-container, .listenon-container .info-inner-container, .info-container .cover-inner-container, .info-container .info-inner-container {
    width: 100%; }

.info-container .info-inner-container {
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 60em) {
    .info-container .info-inner-container {
      padding: 0 0 0 1em; } }
  .info-container .info-inner-container h3 {
    font-size: 1.875em;
    font-weight: 700;
    padding-top: 1em;
    padding-bottom: .133em;
    margin-left: -.1em; }
    @media all and (max-width: 385px) {
      .info-container .info-inner-container h3 {
        font-size: 1.6875em;
        font-size: 7.305vw; } }
    @media all and (max-width: 308px) {
      .info-container .info-inner-container h3 {
        font-size: 1.5em; } }
    @media all and (min-width: 60em) {
      .info-container .info-inner-container h3 {
        margin-top: -.125em; } }
  .info-container .info-inner-container h4:after {
    content: '\00a0\00a0\2022\00a0\00a0';
    font-family: sans-serif;
    display: inline-block;
    transform: translateY(0.1em); }
  .info-container .info-inner-container h4, .info-container .info-inner-container h5 {
    font-weight: 500; }
  .info-container .info-inner-container .order, .info-container .info-inner-container .time {
    font-variant-numeric: tabular-nums; }
  .info-container .info-inner-container .tracklist {
    list-style-type: none; }
    .info-container .info-inner-container .tracklist .track {
      border-top: 0.1em solid #111;
      padding: .4em 0 .5em; }
      .info-container .info-inner-container .tracklist .track:first-child {
        margin-top: 2em; }
      .info-container .info-inner-container .tracklist .track:last-child {
        border-bottom: 0.1em solid #111; }
    .info-container .info-inner-container .tracklist .justify {
      line-height: 1.4;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start; }
      .info-container .info-inner-container .tracklist .justify .order {
        flex: 0 0 2em; }
      .info-container .info-inner-container .tracklist .justify .title {
        flex: 1 0 auto;
        max-width: calc(100% - 5em);
        text-overflow: ellipsis; }
      .info-container .info-inner-container .tracklist .justify .time {
        flex: 0 1 3em;
        align-self: flex-start; }

.extend-icon {
  pointer-events: none; }
  .project.active .extend-icon .plus {
    display: none; }

.project:not(.active) {
  cursor: pointer; }
  .project:not(.active):hover .extend line {
    stroke: #fff; }
  .project:not(.active):hover .extend circle {
    fill: #111; }

.extend {
  width: 3em;
  height: 3em;
  position: absolute;
  right: .8em;
  bottom: -1.6em; }
  .extend:hover line {
    stroke: #fff; }
  .extend:hover circle {
    fill: #111; }

.external-link-icon {
  position: absolute;
  bottom: 1em;
  right: .6em;
  width: 1.66em;
  height: auto; }

@media all and (min-width: 60em) {
  .release {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: min-content max-content;
    grid-template-areas: "left-1 right" "left-2 right"; }
  .cover-container {
    grid-area: left-1; }
  .info-container {
    grid-area: right; } }

.listenon-container {
  grid-area: left-2; }

#main-section-content {
  display: flex;
  flex-flow: row wrap;
  /* align-items: flex-end; */
  /* justify-content: unset; */
  align-content: space-between; }

#error {
  flex: 1 0 auto; }

.footer {
  flex: 0 0 auto; }

.gig-wrapper h2::selection, .gig-wrapper h3::selection, .gig-wrapper h4::selection, .gig-wrapper h5::selection {
  color: #111;
  background-color: rgba(0, 0, 0, 0.15); }

@media all and (max-width: 30em) {
  .article.projects:not(:first-of-type) .projects-header {
    padding-top: .5em; } }

@media all and (min-width: 60em) {
  .article.projects:not(:first-of-type) {
    padding-top: 0em; } }
